<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12" sm="6" md="4">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateRange" hide-details="" dense label="Seleccionar rango" filled
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" range no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="onDateRangeSelect">Confirmar</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" md="4">
                <v-autocomplete @change="onDateRangeSelect" dense filled v-model="searchUserType" :items="userTypes"
                    label="Seleccionar tipo de usuario" hide-details prepend-inner-icon="mdi-account"></v-autocomplete>
            </v-col>
            <v-divider vertical></v-divider>

            <v-col cols="12" sm="12" md="4">
                <v-text-field filled v-model="search" hide-details="" dense label="Buscar"
                    prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-data-table fixed-header :headers="headers" :items="workoutsDisplay" mobile-breakpoint="0"
                    class="elevation-1" :loading="loading" sort-by="dateCheckin" :search="search"
                    item-key="dateCheckin">
                    <template v-slot:[`header.actions`]="{ header }">
                        <v-btn color="primary darken-2" @click="downloadCsvReport" v-show="workoutsDisplay.length > 0">
                            <v-icon left>mdi-file-download</v-icon>
                            Descargar reporte
                        </v-btn>
                    </template>


                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            search: null,
            menu: false,
            dateRange: [
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD'),
            ],
            dateText: '',
            users: [],
            workouts: [],
            workoutsDisplay: [],
            chartOptions: {
                chart: { id: 'workouts-over-time' },
                xaxis: { type: 'datetime' },
                yaxis: { title: { text: 'Workouts' } },
            },
            series: [{ name: 'Workouts', data: [] }],
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Nombre usuario', value: 'username' },
                { text: 'Fecha acceso', value: 'dateCheckin' },
                { text: 'accione', value: 'actions', sortable: false }
            ],
            loading: false,
            searchUserType: null,
            userTypes: ["paselibre", "gratis", "entrenador", "admin", "representante", "opengym", "online", "usuario"]
        };
    },
    mounted() {

    },
    methods: {
        async fetchUsersByType(type) {
            this.loading = true;
            const db = getFirestore();
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('type', '==', type));
            const querySnapshot = await getDocs(q);
            this.users = [];
            querySnapshot.forEach((doc) => {
                this.users.push({ ...doc.data(), id: doc.id });
            });
            this.fetchWorkouts();
        },
        async fetchWorkouts() {
            this.workouts = [];

            const db = getFirestore();
            const promises = this.users.map(user => {
                const workoutsRef = collection(db, `users/${user.id}/workouts`);
                const q = query(
                    workoutsRef,
                    where('weekStart', '>=', moment(this.dateRange[0] + ' 00:00:00').subtract('7', 'days').toDate()),
                    where('weekStart', '<=', moment(this.dateRange[1] + ' 23:59:59').add('7', 'days').toDate()),
                    orderBy('weekStart')
                );
                return getDocs(q); // Return the promise
            });

            // Wait for all queries to complete
            const results = await Promise.all(promises);
            results.forEach((querySnapshot, index) => {
                const user = this.users[index];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    data.dates.forEach((date) => {

                        if (moment(date).isBetween(this.dateRange[0], this.dateRange[1])) {

                            this.workouts.push({
                                id: user.id,
                                username: user.displayName,
                                dateCheckin: date,
                            });
                        }







                    });
                });
            });

            this.prepareWorkoutsDisplay();
        },

        prepareWorkoutsDisplay() {

            this.workoutsDisplay = this.workouts.map((workout) => ({
                id: workout.id,
                username: workout.username,
                dateCheckin: workout.dateCheckin,
            }));
            this.loading = false;
        },

        onDateRangeSelect() {
            this.menu = false;
            if (this.dateRange.length === 2 && this.searchUserType) {
                this.fetchUsersByType(this.searchUserType);
            }
        },
        downloadCsvReport() {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent += 'ID,Nombre usuario,Fecha acceso\n';
            this.workoutsDisplay.forEach((workout) => {
                csvContent += `${workout.id},${workout.username},${workout.dateCheckin}\n`;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `Asistencias_${this.searchUserType}.csv`);
            document.body.appendChild(link);
            link.click();
        },
    },
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
    color: #000 !important;
}
</style>

<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12">

                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330"
                    min-width="330">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled hide-details="" v-model="dateRange" label="Seleccionar un rango de fechas"
                            readonly v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" range width="100%" scrollable
                        :min="dateRange.length > 0 ? dateRange[0] : null">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary darken-1" @click=" dateRange = []">Cancelar</v-btn>
                        <v-btn text color="primary darken-1" @click="menu = false; fetchAllData()">Confirmar</v-btn>

                    </v-date-picker>
                </v-menu>

                <v-alert text v-if="!loading && series[0].data.length">
                    Máximo: {{ maxUsers }}<br />
                    Mínimo: {{ minUsers }}<br />

                    Crecimiento: {{ percentageGrowth }}%
                </v-alert>

                <apexchart type="line" :options="chartOptions" :series="series" v-if="!loading"></apexchart>
                <v-skeleton-loader type="image" v-else></v-skeleton-loader>



            </v-col>

            <v-col cols="12">
                <v-alert type="info" text>
                    Este gráfico muestra la cantidad de usuarios 'activos' en el sistema en un rango de fechas, activos
                    no significa que asistan a clases o usen la aplicación, sino que están habilitados para hacerlo.
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import ApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    components: {
        apexchart: ApexCharts,
    },
    data() {
        return {
            menu: false,

            loading: false,
            dateRange: [],
            users: [],
            disabledHistory: [],
            chartOptions: {

                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1'
                },
                chart: {
                    id: "users-availability-report",
                    toolbar: {
                        show: false
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: "Cantidad de usuarios activos",
                    align: "left",
                },
            },
            series: [
                {
                    name: "Usuarios activos",
                    data: [],
                },
            ],
            maxUsers: 0,
            minUsers: 0,
            monthlyStats: {},
            percentageGrowth: 0,


        };
    },
    created() {
        this.fetchAllData();
    },
    methods: {
        async fetchAllData() {


            let start, end;

            if (this.dateRange.length == 2) {
                start = this.dateRange[0]
                end = this.dateRange[1]
            } else {
                // here start is the start of the month and end is today at 23:59:59
                let date = moment()
                let startOfMonth = moment().startOf('month')

                start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                this.dateRange = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
            }

            this.loading = true;
            const functions = getFunctions();
            const getUserAndDisabledHistory = httpsCallable(functions, "getUserAvailabilityData");

            try {
                const result = await getUserAndDisabledHistory({});
                this.users = result.data.users;
                this.disabledHistory = result.data.disabledHistory;
                this.updateDisplayedData(); // Initialize display
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        updateDisplayedData() {
            const startDate = moment(this.dateRange[0]).startOf("day");
            const endDate = moment(this.dateRange[1]).endOf("day");

            const filteredCategories = [];
            const filteredData = [];

            let activeUsers = {};

            // Adjust disabled status based on disabledHistory
            this.disabledHistory.forEach(record => {
                let date = record.date.toDate ? record.date.toDate() : new Date(record.date._seconds * 1000);
                const disabledDate = moment(date);
                if (disabledDate <= endDate && activeUsers[record.id]) {
                    activeUsers[record.id] = false; // Set user as disabled
                }
            });

            // Initialize active users from creation date
            this.users.forEach(user => {
                const createdDate = moment(user.createddate);
                if (createdDate <= endDate) {
                    activeUsers[user.userid] = user.disabled ? false : true;
                }
            });

            // Variables for extra stats
            let monthlyStats = {};
            let maxUsers = 0, minUsers = Infinity;
            let currentMonth = startDate.format("YYYY-MM");
            let availableUsersForMonth = 0, totalDaysInMonth = 0;

            // Calculate the number of available users for each day
            for (let date = startDate; date <= endDate; date = date.clone().add(1, "days")) {
                let availableUsers = 0;
                for (const [userId, isActive] of Object.entries(activeUsers)) {
                    if (isActive) {
                        const user = this.users.find(user => user.userid === userId);
                        const createdDate = moment(user.createddate);
                        if (createdDate <= date) {
                            availableUsers++;
                        }
                    }
                }

                filteredCategories.push(date.format("YYYY-MM-DD"));
                filteredData.push(availableUsers);

                // Track max and min users
                if (availableUsers > maxUsers) maxUsers = availableUsers;
                if (availableUsers < minUsers) minUsers = availableUsers;

                // Track monthly totals
                const dateMonth = date.format("YYYY-MM");
                if (dateMonth === currentMonth) {
                    availableUsersForMonth += availableUsers;
                    totalDaysInMonth++;
                } else {
                    // Store the monthly average when the month changes
                    monthlyStats[currentMonth] = availableUsersForMonth / totalDaysInMonth;

                    // Reset for the new month
                    currentMonth = dateMonth;
                    availableUsersForMonth = availableUsers;
                    totalDaysInMonth = 1;
                }
            }

            // Handle the last month's data
            if (totalDaysInMonth > 0) {
                monthlyStats[currentMonth] = availableUsersForMonth / totalDaysInMonth;
            }

            // Calculate percentage growth between months if there are multiple months
            const months = Object.keys(monthlyStats);
            let percentageGrowth = 0;
            if (months.length > 1) {
                const firstMonthAvg = monthlyStats[months[0]];
                const lastMonthAvg = monthlyStats[months[months.length - 1]];
                percentageGrowth = ((lastMonthAvg - firstMonthAvg) / firstMonthAvg) * 100;
            } else {
                // If there's only one month, the difference between max and min is the growth
                percentageGrowth = ((maxUsers - minUsers) / minUsers) * 100;
            }

            // Display the calculated statistics (you can move this to a UI component)
            console.log("Max Users:", maxUsers);
            console.log("Min Users:", minUsers);
            console.log("Monthly Average Users:", monthlyStats);
            console.log("Percentage Growth:", percentageGrowth);

            // Update the chart without touching the existing logic
            this.chartOptions.xaxis.categories = filteredCategories;
            this.series[0].data = filteredData;
            this.loading = false;

            // Save statistics to display in UI
            this.maxUsers = maxUsers;
            this.minUsers = minUsers;
            this.percentageGrowth = percentageGrowth.toFixed(2);
        },
    },
};
</script>

<style scoped>
/* Add any additional styling here */
</style>

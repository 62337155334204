<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled hide-details="" v-model="dates" label="Seleccionar un rango de fechas" readonly
              v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
          </template>
          <v-date-picker v-model="dates" range width="100%" scrollable :min="dates.length > 0 ? dates[0] : null">
            <v-spacer></v-spacer>
            <v-btn text color="primary darken-1" @click=" dates = []">Cancelar</v-btn>
            <v-btn text color="primary darken-1" @click="menu = false; getData()">Confirmar</v-btn>

          </v-date-picker>
        </v-menu>

      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text v-if="!loading">
            <vue-apex-charts type="bar" height="350" :options="chartOptions" :series="chartSeries"></vue-apex-charts>
          </v-card-text>
          <v-skeleton-loader v-else type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text v-if="!loading">
            <vue-apex-charts type="pie" height="350" :options="pieChartOptionsIngress"
              :series="pieChartSeriesIngress"></vue-apex-charts>
          </v-card-text>
          <v-skeleton-loader v-else type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text v-if="!loading">
            <vue-apex-charts type="pie" height="350" :options="pieChartOptionsEgress"
              :series="pieChartSeriesEgress"></vue-apex-charts>
          </v-card-text>
          <v-skeleton-loader v-else type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue';
import { collection, getDocs, query, where, getFirestore } from 'firebase/firestore';
import moment from 'moment';
import { groupBy, mapValues } from 'lodash';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      dates: [],
      menu: false,
      headers: [
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha', value: 'date' },
        { text: 'Categoría', value: 'category' },
        { text: 'Cantidad', value: 'amount' },
        { text: 'Descripción', value: 'description' },
        { text: 'Cédula', value: 'user_id' },
        { text: 'Monto', value: 'payAmount' },
        { text: 'Moneda', value: 'currency' },
        { text: 'Estado', value: 'status' },
        { text: 'Metodo de pago', value: 'payMethod' },
        { text: 'Importe', value: 'total' },
      ],
      combinedData: [],
      loading: true,
      chartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'bar',
          stacked: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Mes',
          },
        },
        yaxis: {
          title: {
            text: 'Monto',
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        title: {
          text: 'Total de ingresos y egresos por mes',
          align: 'center',
          style: {
            fontSize: '16px',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: val => val.toFixed(2),
        },
      },
      chartSeries: [],
      pieChartOptionsIngress: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        labels: [],
        title: {
          text: 'Distribución de Ingresos por Categoría',
          align: 'center',
        },
      },
      pieChartSeriesIngress: [],
      pieChartOptionsEgress: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        labels: [],
        title: {
          text: 'Distribución de Egresos por Categoría',
          align: 'center',
        }
      },
      pieChartSeriesEgress: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      let start, end;

      if (this.dates.length == 2) {
        start = new Date(this.dates[0] + " 00:00:00")
        end = new Date(this.dates[1] + " 23:59:59")
      } else {
        // here start is the start of the month and end is today at 23:59:59
        let date = moment()
        let startOfMonth = moment().startOf('month')

        start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
        end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

        this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
      }


      const db = getFirestore();

      const paymentQuery = query(collection(db, 'payments'), where('createdAt', '>=', start), where('createdAt', '<=', end));
      const ingressEgressQuery = query(collection(db, 'ingress-egress'), where('date', '>=', start), where('date', '<=', end));

      const [paymentSnapshot, ingressEgressSnapshot] = await Promise.all([
        getDocs(paymentQuery),
        getDocs(ingressEgressQuery),
      ]);

      const payments = paymentSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          date: moment(data.createdAt.toDate()).format('YYYY-MM-DD'),
          type: 'ingress',
          payAmount: parseInt(data.total),
          currency: 'UYU',  // Assume UYU for all payments if not specified
          category: 'Membresías',  // Assume membership for all payments if not specified
        };
      });

      const entries = ingressEgressSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          date: moment(data.date.toDate()).format('YYYY-MM-DD'),
        };
      });

      this.combinedData = [...payments, ...entries];

      // Sort by date

      this.combinedData.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
        return 0;
      });

      this.aggregateData();
      this.loading = false;
    },
    aggregateData() {
      const ingressData = this.combinedData.filter(item => item.type === 'ingress');
      const egressData = this.combinedData.filter(item => item.type === 'egress');

      const groupedIngress = this.groupByDateAndCurrency(ingressData);
      const groupedEgress = this.groupByDateAndCurrency(egressData);

      const allCategories = Array.from(new Set([...Object.keys(groupedIngress), ...Object.keys(groupedEgress)]));

      const monthCategories = allCategories.map(category => moment(category, 'YYYY-MM').format('YYYY-MM'));

      this.chartOptions.xaxis.categories = monthCategories;

      const currencies = Array.from(new Set(this.combinedData.map(item => item.currency)));

      this.chartSeries = currencies.flatMap(currency => [
        {
          name: `Ingreso (${currency})`,
          data: allCategories.map(category => groupedIngress[category]?.[currency] || 0),
        },
        {
          name: `Egreso (${currency})`,
          data: allCategories.map(category => groupedEgress[category]?.[currency] || 0),
        },
      ]);

      // Aggregate data for pie charts
      this.aggregatePieChartData(ingressData, egressData);
    },
    groupByDateAndCurrency(data) {
      const groupedByDate = groupBy(data, item => moment(item.date).format('YYYY-MM'));
      return mapValues(groupedByDate, entries =>
        entries.reduce((acc, entry) => {
          if (!acc[entry.currency]) acc[entry.currency] = 0;
          acc[entry.currency] += parseFloat(entry.payAmount);
          return acc;
        }, {})
      );
    },
    aggregatePieChartData(ingressData, egressData) {
      const groupedIngressByCategory = groupBy(ingressData, 'category');
      const groupedEgressByCategory = groupBy(egressData, 'category');

      const ingressCategories = Object.keys(groupedIngressByCategory);
      const egressCategories = Object.keys(groupedEgressByCategory);

      this.pieChartOptionsIngress.labels = ingressCategories;
      this.pieChartOptionsEgress.labels = egressCategories;

      this.pieChartSeriesIngress = ingressCategories.map(category =>
        groupedIngressByCategory[category].reduce((sum, item) => sum + parseFloat(item.payAmount), 0)
      );

      this.pieChartSeriesEgress = egressCategories.map(category =>
        groupedEgressByCategory[category].reduce((sum, item) => sum + parseFloat(item.payAmount), 0)
      );
    },
  },
};
</script>

<style>
/* Add any additional styling here */
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- Date Picker inside v-menu -->
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field filled hide-details="" v-model="dateRange" label="Seleccionar un rango de fechas" readonly
              v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range width="100%" scrollable
            :min="dateRange.length > 0 ? dateRange[0] : null">
            <v-spacer></v-spacer>
            <v-btn text color="primary darken-1" @click="dateRange = []; menu = false">Cancelar</v-btn>
            <v-btn text color="primary darken-1" @click="menu = false; fetchData()">Confirmar</v-btn>
          </v-date-picker>
        </v-menu>

        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <apexchart v-if="chartData.length > 0" type="bar" :options="chartOptions" :series="chartData"></apexchart>
        <v-skeleton-loader v-else-if="loading" type="image"></v-skeleton-loader>
        <v-alert v-else-if="dateRange.length < 2" type="info" color="primary darken-3">
          Selecciona un rango de fechas para ver las estadísticas.
        </v-alert>
        <v-alert v-else type="info" color="primary darken-3">
          No hay datos para mostrar en el rango de fechas seleccionado.
        </v-alert>
      </v-col>

      <v-col cols="12" md="6">
        <apexchart v-if="dailyChartData.length > 0" type="bar" :options="dailyChartOptions" :series="dailyChartData">
        </apexchart>
        <v-skeleton-loader v-else-if="loading" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="chartData.length > 0">
      <v-col cols="12" lg="8" offset-lg="2">
        <v-card>
          <v-card-title>Utilizadas por día (%)</v-card-title>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <!-- show nombre only if stat.class has different values and not all equal-->
                  <th
                    v-if="statistics.length > 1 && statistics.some((stat, index) => stat.class !== statistics[0].class)">
                    Nombre de clase</th>
                  <th>Día de la Semana</th>
                  <th>Promedio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(stat, index) in statistics" :key="index">
                  <td
                    v-if="statistics.length > 1 && statistics.some((stat, index) => stat.class !== statistics[0].class)">
                    {{ stat.class }}</td>
                  <td>{{ stat.day }}</td>
                  <td>{{ stat.mean.toFixed(2) * 100 }}%</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      loading: false,
      menu: false,
      dateRange: [],
      chartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          id: 'class-stats',
          stacked: true
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: 'Cantidad'
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'center'
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        }
      },
      chartData: [],
      dailyChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          id: 'daily-class-stats',
          stacked: true
        },
        xaxis: {
          categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
        },
        yaxis: {
          title: {
            text: 'Cantidad'
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'center'
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          },
          dropShadow: {
            enabled: false
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        }
      },
      dailyChartData: [],
      statistics: []
    };
  },
  methods: {
    async fetchData() {

      this.loading = true;
      let start, end;

      if (this.dateRange.length == 2) {
        start = new Date(this.dateRange[0] + " 00:00:00").toISOString().split('T')[0];
        end = new Date(this.dateRange[1] + " 23:59:59").toISOString().split('T')[0];
      } else {
        // here start is the start of the month and end is today at 23:59:59
        let date = moment()
        let startOfMonth = moment().startOf('month')

        start = moment(new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")).format('YYYY-MM-DD')
        end = moment(new Date(date.format('YYYY-MM-DD') + " 23:59:59")).format('YYYY-MM-DD')

        this.dateRange = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
      }

      const db = getFirestore();
      const scheduleRef = collection(db, 'schedule');
      const scheduleQuery = query(scheduleRef, where('__name__', '>=', start), where('__name__', '<=', end));
      const snapshot = await getDocs(scheduleQuery);

      const fetchPromises = snapshot.docs.map(async (doc) => {
        const schedulesRef = collection(doc.ref, 'schedules');
        return getDocs(schedulesRef);
      });

      const schedulesSnapshots = await Promise.all(fetchPromises);

      let classStats = {};
      let dailyStats = {};
      let weeklyStats = {};

      schedulesSnapshots.forEach((schedulesSnapshot, index) => {
        const doc = snapshot.docs[index];

        schedulesSnapshot.forEach((classDoc) => {
          const classData = classDoc.data();
          const classType = classData.type;
          const time = new Date(classData.startDate).toLocaleTimeString();
          const startTime = new Date(classData.startDate);
          const dayOfWeek = new Date(classData.startDate).getDay();
          const dayString = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'][dayOfWeek];

          if (!classStats[time]) {
            classStats[time] = {};
          }
          if (!classStats[time][classType]) {
            classStats[time][classType] = { checkedIn: 0, notAttended: 0, lateCheckedIn: 0 };
          }

          if (!dailyStats[dayString]) {
            dailyStats[dayString] = {};
          }
          if (!dailyStats[dayString][classType]) {
            dailyStats[dayString][classType] = { checkedIn: 0, notAttended: 0, lateCheckedIn: 0 };
          }

          if (!weeklyStats[classType]) {
            weeklyStats[classType] = {};
          }
          if (!weeklyStats[classType][dayString]) {
            weeklyStats[classType][dayString] = [];
          }

          classData.users.forEach(user => {
            if (user.checkedIn) {

              const checkInTime = new Date(user.checkedIn);
              if (checkInTime - startTime > 10 * 60 * 1000) { // 10 minutes in milliseconds
                classStats[time][classType].lateCheckedIn++;
              } else {
                classStats[time][classType].checkedIn++;
              }

              dailyStats[dayString][classType].checkedIn++;
              weeklyStats[classType][dayString].push(1);
            } else {
              classStats[time][classType].notAttended++;
              dailyStats[dayString][classType].notAttended++;
              weeklyStats[classType][dayString].push(0);
            }
          });
        });
      });

      let seriesData = {
        checkedIn: [],
        notAttended: [],
        lateCheckedIn: []
      };


      let sortedTimes = Object.keys(classStats).sort((a, b) => {
        const aTime = new Date(`2021-01-01 ${a}`);
        const bTime = new Date(`2021-01-01 ${b}`);
        return aTime - bTime;
      });

      for (const time of sortedTimes) {
        for (const [type, stats] of Object.entries(classStats[time])) {
          seriesData.checkedIn.push({
            x: `${time} - ${type}`,
            y: stats.checkedIn
          });
          seriesData.notAttended.push({
            x: `${time} - ${type}`,
            y: stats.notAttended
          });
          seriesData.lateCheckedIn.push({
            x: `${time} - ${type}`,
            y: stats.lateCheckedIn
          });
        }
      }

      let dailySeriesData = {
        checkedIn: [],
        notAttended: [],
        lateCheckedIn: []
      };

      for (let day of ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']) {
        for (const [type, stats] of Object.entries(dailyStats[day] || {})) {
          dailySeriesData.checkedIn.push({
            x: `${type} - ${day}`,
            y: stats.checkedIn
          });
          dailySeriesData.notAttended.push({
            x: `${type} - ${day}`,
            y: stats.notAttended
          });
          dailySeriesData.lateCheckedIn.push({
            x: `${type} - ${day}`,
            y: stats.lateCheckedIn
          });
        }
      }

      this.chartOptions.xaxis.categories = sortedTimes;
      this.chartData = [
        { name: 'Asistencias', data: seriesData.checkedIn },
        { name: 'Reserva sin uso', data: seriesData.notAttended },
        { name: 'Asistencias tardías', data: seriesData.lateCheckedIn }
      ];

      this.dailyChartData = [
        { name: 'Asistencias', data: dailySeriesData.checkedIn },
        { name: 'Reserva sin uso', data: dailySeriesData.notAttended },
      ];

      this.statistics = [];
      for (const [classType, days] of Object.entries(weeklyStats)) {
        for (const [day, values] of Object.entries(days)) {
          const mean = values.reduce((a, b) => a + b, 0) / values.length;
          const sortedValues = [...values].sort((a, b) => a - b);
          const median = sortedValues.length % 2 === 0
            ? (sortedValues[sortedValues.length / 2 - 1] + sortedValues[sortedValues.length / 2]) / 2
            : sortedValues[Math.floor(sortedValues.length / 2)];
          this.statistics.push({
            class: classType,
            day,
            mean,
            median
          });
        }
      }

      this.loading = false;

    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped></style>

<template>
    <v-container class="pt-0" id="alerts">

        <!-- Date Picker inside v-menu -->
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field filled hide-details="" v-model="dates" label="Seleccionar un rango de fechas" readonly
                    v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
            </template>
            <v-date-picker v-model="dates" range width="100%" scrollable :min="dates.length > 0 ? dates[0] : null">
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" @click=" dates = []">Cancelar</v-btn>
                <v-btn text color="primary darken-1" @click="menu = false; fetchAlertsForDate()">Confirmar</v-btn>

            </v-date-picker>
        </v-menu>

        <v-autocomplete v-model="userID" :items="computedUsers" label="Seleccionar usuario (opcional)" filled
            single-line dense hide-details clearable @change="fetchAlertsForDate()"></v-autocomplete>

        <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
            <v-tab>
                <v-icon left>
                    mdi-chart-bar
                </v-icon>
                Resumen </v-tab>

            <v-tab>
                <v-icon left>
                    mdi-alert
                </v-icon>

                Alertas </v-tab>

            <v-tab-item v-if="alerts.length > 0">
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-card class="fill-height">
                            <v-card-text>
                                <apexchart type="line" v-if="alerts.length > 0" :options="lineChartOptions"
                                    :series="lineChartSeries"></apexchart>
                                <v-skeleton-loader v-else height="300" type="image"></v-skeleton-loader>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card class="fill-height">
                            <v-card-text>
                                <apexchart type="donut" v-if="alerts.length > 0" :options="donutChartOptions"
                                    :series="donutChartSeries"></apexchart>
                                <v-skeleton-loader v-else height="300" type="image"></v-skeleton-loader>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card class="fill-height">

                            <v-card-text>
                                <apexchart type="bar" v-if="alerts.length > 0" :options="chartOptions"
                                    :series="weekdaySeries">
                                </apexchart>
                                <v-skeleton-loader v-else height="300" type="image"></v-skeleton-loader>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-card class="fill-height">
                            <v-card-text>
                                <apexchart v-if="alerts.length > 0" type="bar" :options="seenChartOptions"
                                    :series="seenSeries">
                                </apexchart>
                                <v-skeleton-loader v-else height="300" type="image"></v-skeleton-loader>
                            </v-card-text>
                        </v-card>
                    </v-col>



                </v-row>
            </v-tab-item>

            <v-tab-item>


                <!-- Data Table -->
                <v-data-table :loading="loading" :headers="headers" :items="alerts" item-key="id" show-select
                    v-model="selected" mobile-breakpoint="0" class="elevation-1 mt-5">
                    <template v-slot:top>
                        <v-btn :disabled="loading || selected.length == 0" @click="markSelectedAsSeen" class="ma-2"
                            color="primary">Marcar como gestionadas ({{ selected.length }})</v-btn>
                    </template>
                    <template v-slot:item.type="{ item }">
                        <v-chip :color="getColorForDonut(item.type)">

                            <v-icon left>mdi-information-outline</v-icon>
                            <!-- missing ['reservation_not_used','user_disabled','user_subscription_expired','plan_completed'] -->

                            {{ item.type == 'checkin_no_reservation' ? 'Asiste sin reserva previa' : '' }}
                            {{ item.type == 'reservation_not_used' ? 'Reserva no utilizada' : '' }}
                            {{ item.type == 'user_disabled' ? 'Usuario deshabilitado' : '' }}
                            {{ item.type == 'user_subscription_expired' ? 'Uso con suscripcion expirada' : '' }}
                            {{ item.type == 'plan_completed' ? 'Intento asistencia con plan completado' : '' }}
                            {{ item.type == 'user_license_paused' ? 'Uso con licencia activa' : '' }}
                            {{ item.type == 'user_not_found' ? 'Usuario no encontrado' : '' }}
                            {{ item.type == 'late_checkin' ? 'Checkin tardio' : '' }}
                            {{ item.type == 'user_plan_not_found' ? 'Plan no encontrado' : '' }}


                            {{ item.type == 'early_checkin' ? 'Checkin temprano' : '' }}
                            {{ item.type == 'early_checkin_after_start' ? 'Checkin al comenzar' : '' }}

                        </v-chip>
                    </template>
                    <template v-slot:item.seen="{ item }">
                        <v-chip :color="item.seen ? 'success' : 'warning'">
                            <v-icon>
                                {{ item.seen ? 'mdi-check' : 'mdi-close' }}
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item.date="{ item }">
                        {{ getFormattedDate(item.date) }}
                    </template>

                    <template v-slot:item.user_id="{ item }">

                        <v-btn @click="userID = item.user_id; fetchAlertsForDate()" color="primary darken-3" small>{{
                            item.user_id }}<v-icon right>mdi-eye</v-icon> </v-btn>
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs>
        <!-- Mark As Seen Button -->
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import VueApexCharts from "vue-apexcharts";
import moment from 'moment';
import { colors } from 'vuetify/lib';
import { keys } from 'lodash'

export default {
    created() {
        // set chart themes to dark or light mode depending on the current theme 'this.$store.state.isDark' ? 'dark' : 'light'
        this.chartOptions.theme.mode = this.$store.state.isDark ? 'dark' : 'light';
        this.lineChartOptions.theme.mode = this.$store.state.isDark ? 'dark' : 'light';
        this.donutChartOptions.theme.mode = this.$store.state.isDark ? 'dark' : 'light';
        this.seenChartOptions.theme.mode = this.$store.state.isDark ? 'dark' : 'light';
    },
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            userID: null,
            tab: 0,
            dates: [], // Default to today's date
            alerts: [],
            headers: [
                { text: 'Gestionada', value: 'seen' },
                { text: 'Tipo', value: 'type' },
                { text: 'Fecha', value: 'date' },
                //{ text: 'Descripcion', value: 'description' },
                { text: 'ID Usuario', value: 'user_id' }
            ],
            selected: [],
            menu: false,
            loading: false,
            chartOptions: {
                theme: {
                    mode: 'dark',
                },
                chart: {
                    id: 'alerts-by-weekday',
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
                },
                title: {
                    text: 'Alertas por Días de la Semana',
                    align: 'center'
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                fill: {
                    colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4']
                },
                dataLabels: {
                    enabled: true
                }
            },
            weekdaySeries: [{
                name: 'Alertas',
                data: []
            }],
            lineChartOptions: {
                theme: {
                    mode: 'dark',
                },
                title: {
                    text: "Alertas por dia",
                    align: 'center',
                    style: {
                        fontSize: '13px',
                    }
                },
                chart: {
                    id: "alerts-over-time",
                    type: "line",
                    foreColor: '#ccc',
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                tooltip: {
                    theme: 'light'
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ffffff30'
                }
            },
            lineChartSeries: [
                {
                    name: "Alertas",
                    data: []
                }
            ],
            donutChartOptions: {
                theme: {
                    mode: 'dark',
                },
                title: {
                    text: "Recuento de alertas",
                    align: 'center',
                    style: {
                        fontSize: '13px',
                    }
                },
                chart: {
                    foreColor: '#ccc',
                    id: "alerts-by-type",
                    type: "donut",
                    toolbar: {
                        show: false,
                    },
                },
                labels: [],
                tooltip: {
                    theme: 'light'
                },
                grid: {
                    borderColor: '#ffffff30'
                },
                colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4']

            },
            donutChartSeries: [],
            seenChartOptions: {
                theme: {
                    mode: 'dark',
                },
                chart: {
                    id: 'alerts-seen-status',
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['Gestionada', 'Pendiente']
                },
                title: {
                    text: 'Estado de Alertas',
                    align: 'center'
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },

                dataLabels: {
                    enabled: true
                }
            },
            seenSeries: [{
                name: 'Estado',
                data: []
            }]
        }
    },
    methods: {
        async fetchAlertsForDate() {
            try {
                this.alerts = [];
                this.loading = true;
                this.selected = [];
                const db = getFirestore();
                let start, end;

                if (this.dates.length == 2) {
                    start = new Date(this.dates[0] + " 00:00:00")
                    end = new Date(this.dates[1] + " 23:59:59")
                } else {
                    // here start is the start of the month and end is today at 23:59:59
                    let date = moment()
                    let startOfMonth = moment().startOf('month')

                    start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                    end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                    this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
                }

                let q;

                if (this.userID) {
                    q = query(
                        collection(db, 'alerts'),
                        where('date', '>=', start),
                        where('date', '<=', end),
                        where('user_id', '==', this.userID)
                    );
                } else {
                    q = query(
                        collection(db, 'alerts'),
                        where('date', '>=', start),
                        where('date', '<=', end)
                    );
                }

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach(docSnapshot => {
                    const alertData = docSnapshot.data();
                    this.alerts.push({
                        id: docSnapshot.id,
                        ...alertData
                    });
                });

                // Update line chart data
                this.lineChartOptions.xaxis.categories = this.alertsOverTimeData.map(data => data.x);
                this.lineChartSeries[0].data = this.alertsOverTimeData.map(data => data.y);

                // Update donut chart data
                this.donutChartOptions.labels = this.alertsByTypeData.map(data => data.label);
                this.donutChartSeries = this.alertsByTypeData.map(data => data.value);

                // Update bar chart data
                this.weekdaySeries[0].data = this.weekdayAlertsData;

                // Inside fetchAlertsForDate method, after fetching alerts:
                this.seenSeries[0].data = this.alertsSeenData.map(data => data.y);


                this.$forceUpdate();


            } catch (error) {
                console.log(error);
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error al cargar los datos',
                    type: 'error'
                });
            } finally {
                this.loading = false;
            }
        },
        async markSelectedAsSeen() {
            if (this.selected.length == 0)
                return;
            this.loading = true;
            const db = getFirestore();
            for (let alert of this.selected) {
                if (!alert.seen) {
                    await updateDoc(doc(db, 'alerts', alert.id), {
                        seen: true
                    });
                }
            }
            // Refresh data
            this.fetchAlertsForDate();


            this.$notify({
                group: 'feedback',
                title: 'Exito',
                text: 'Alertas marcadas como gestionadas',
                type: 'success'
            });
        },
        getLabelForAlertType(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 'Asiste sin reserva previa';
                case 'reservation_not_used':
                    return 'Reserva no utilizada';
                case 'user_disabled':
                    return 'Usuario deshabilitado';
                case 'user_subscription_expired':
                    return 'Uso con suscripcion expirada';
                case 'plan_completed':
                    return 'Intento asistencia con plan completado';
                case 'user_license_paused':
                    return 'Uso con licencia activa';
                case 'user_not_found':
                    return 'Usuario no encontrado';
                case 'late_checkin':
                    return 'Checkin tardio';
                case 'user_plan_not_found':
                    return 'Plan no encontrado';
                default:
                    return type;
            }
        },
        getColorForDonut(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 'red';
                case 'reservation_not_used':
                    return 'orange';
                case 'user_disabled':
                    return 'yellow';
                case 'user_subscription_expired':
                    return 'green';
                case 'plan_completed':
                    return 'blue';
                case 'user_license_paused':
                    return 'purple';
                case 'user_not_found':
                    return 'black';
                case 'late_checkin':
                    return 'grey';
                case 'user_plan_not_found':
                    return 'pink';

                default:
                    return 'primary';
            }
        },
        getFormattedDate(date) {
            return moment(date.seconds * 1000).format('DD/MM/YYYY');
        }
    },
    mounted() {
        this.fetchAlertsForDate();
    },
    computed: {
        alertsOverTimeData() {
            const groupedByDate = {};
            this.alerts.forEach(alert => {
                const date = new Date(alert.date.seconds * 1000)
                const formattedDate = date.toISOString().split('T')[0];
                if (!groupedByDate[formattedDate]) {
                    groupedByDate[formattedDate] = 0;
                }
                groupedByDate[formattedDate]++;


            });
            return Object.keys(groupedByDate).map(date => ({
                x: date,
                y: groupedByDate[date]
            }));
        },
        alertsByTypeData() {
            const countByType = {};
            this.alerts.forEach(alert => {
                const { type } = alert;
                if (!countByType[type]) {
                    countByType[type] = 0;
                }
                countByType[type]++;
            });
            return Object.entries(countByType).map(([type, count]) => ({
                label: this.getLabelForAlertType(type),
                value: count
            }));
        },
        weekdayAlertsData() {
            const weekdayCount = {
                'Domingo': 0,
                'Lunes': 0,
                'Martes': 0,
                'Miércoles': 0,
                'Jueves': 0,
                'Viernes': 0,
                'Sábado': 0
            };

            this.alerts.forEach(alert => {
                const alertDate = new Date(alert.date.seconds * 1000) // Assuming 'date' is a Firebase Timestamp
                const dayName = new Intl.DateTimeFormat('es-ES', { weekday: 'long' }).format(alertDate);
                weekdayCount[dayName.charAt(0).toUpperCase() + dayName.slice(1)]++; // Capitalize first letter
            });

            return Object.entries(weekdayCount).map(([day, count]) => ({
                x: day,
                y: count
            }));
        },
        alertsSeenData() {
            let gestionada = 0;
            let pendiente = 0;

            this.alerts.forEach(alert => {
                if (alert.seen) {
                    gestionada++;
                } else {
                    pendiente++;
                }
            });

            return [
                { x: 'Gestionada', y: gestionada },
                { x: 'Pendiente', y: pendiente }
            ];
        },
        computedUsers() {
            // users can be repeated
            let users = this.alerts.map(alert => alert.user_id);
            return [...new Set(users)];
        }
    },
    watch: {
        // Watcher to update the chart when alerts data changes
        weekdayAlertsData(newData) {
            this.weekdaySeries[0].data = newData;
        }
    }
};
</script>

<style>
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background-color: transparent !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    background: #000 !important;
    color: #fff !important;
    border-color: var(--v-primary-base) !important;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #000 !important;
}


.v-autocomplete__content {
    background: var(--v-primary-darken4) !important;
}

#alerts .v-skeleton-loader__image {
    height: 100% !important;
}
</style>

<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>

                <apexchart v-if="pieChartData.length > 0" type="pie" :options="pieChartOptions" :series="pieChartData">
                </apexchart>





            </v-col>
            <v-col cols="12" md="6">
                <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>

                <v-data-table v-if="usersWithPayments.length > 0" :headers="tableHeaders" :items="usersWithPayments"
                    mobile-breakpoint="0" :search="search">
                    <template v-slot:top>
                        <v-text-field filled rounded v-model="search" hide-details="" label="Buscar"
                            prepend-inner-icon="mdi-magnify" class="mx-4 rounded-lg"></v-text-field>
                    </template>
                </v-data-table>
            </v-col>

        </v-row>
        <v-alert type="info" v-if="pieChartData.length === 0 && !loading">
            No hay datos para mostrar
        </v-alert>
    </v-container>
</template>

<script>
import { collection, query, where, getDocs, getFirestore, doc, getDoc } from "firebase/firestore";
import VueApexCharts from 'vue-apexcharts';

export default {
    name: "UserPaymentsPieChart",
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            loading: true,
            pieChartData: [],
            usersWithPayments: [],
            search: '',
            pieChartOptions: {
                chart: {
                    type: 'pie'
                },
                labels: [],
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1',
                },
                title: {
                    text: '% de transiciones',
                    align: 'center'
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " usuarios";
                        }
                    }
                }
            },
            tableHeaders: [
                { text: 'Nombre', value: 'name' },
                { text: 'Id', value: 'id' },
                { text: 'Edad', value: 'age' },
                { text: 'Dirección', value: 'address' }
            ]
        };
    },
    methods: {
        calculateAge(birthDate) {
            const today = new Date();
            const birthDateObj = new Date(birthDate);
            let age = today.getFullYear() - birthDateObj.getFullYear();
            const monthDiff = today.getMonth() - birthDateObj.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
                age--;
            }
            return age;
        },
        async fetchData() {
            const db = getFirestore();
            const usersQuery = query(collection(db, "users"), where("type", "==", "paselibre"));
            const usersSnapshot = await getDocs(usersQuery);
            const usersWithPayments = [];
            const usersWithoutPayments = [];

            const paymentChecks = usersSnapshot.docs.map(async (userDoc) => {
                const user = userDoc.data();
                const paymentQuery = query(collection(db, "payments"), where("id", "==", userDoc.id));
                const paymentSnapshot = await getDocs(paymentQuery);
                if (paymentSnapshot.docs.length > 0) {
                    usersWithPayments.push({
                        name: user.displayName || 'Sin Nombre',
                        id: user.id || 'No Id',
                        age: this.calculateAge(user.birthDate),
                        address: user.address || 'Sin Dirección',
                    });
                } else {
                    usersWithoutPayments.push(user);
                }
            });

            await Promise.all(paymentChecks);

            this.usersWithPayments = usersWithPayments;
            this.pieChartOptions.labels = ['Transicionados', 'Sin transicionar'];
            this.pieChartData = [usersWithPayments.length, usersWithoutPayments.length];
            this.loading = false;
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style>
/* Add any custom styles here */
</style>

<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <v-skeleton-loader class="mb-4" type="image" v-if="loading"></v-skeleton-loader>
                <apexchart v-else type="bar" :options="barChartOptions" :series="barChartData">
                </apexchart>

            </v-col>

            <v-col cols="12" md="6">
                <!-- <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
                <div id="map" style="height:100%; min-height:300px" v-if="coordinates.length > 0"></div> -->
                <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
                <apexchart v-else type="pie" :options="pieChartOptions" :series="pieChartData">
                </apexchart>
            </v-col>

        </v-row>
        <v-alert type="info"
            v-if="barChartData.length === 0 && pieChartData.length === 0 && coordinates.length === 0 && !loading">
            No hay datos para mostrar
        </v-alert>
    </v-container>
</template>

<script>
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';

export default {
    name: "UserCharts",
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            loading: true,
            barChartData: [],
            pieChartData: [],
            coordinates: [],
            barChartOptions: {

                chart: {
                    type: 'bar'
                },
                xaxis: {
                    categories: []
                },
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1'
                },
                title: {
                    text: 'Edades de los usuarios agrupadas por rango de 5 años',
                    align: 'center'
                },
                yaxis: {
                    title: {
                        text: 'Cantidad de usuarios'
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " usuarios";
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
            },
            pieChartOptions: {

                chart: {
                    type: 'pie'
                },
                labels: [],
                theme: {
                    palette: 'palette1',
                    mode: this.$store.state.isDark ? 'dark' : 'light'
                },
                title: {
                    text: 'Tipos de usuarios',
                    align: 'center'
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " usuarios";
                        }
                    }
                }
            }
        };
    },
    methods: {
        calculateAge(birthDate) {
            const today = new Date();
            const birthDateObj = new Date(birthDate);
            let age = today.getFullYear() - birthDateObj.getFullYear();
            const monthDiff = today.getMonth() - birthDateObj.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
                age--;
            }
            return age;
        },
        async fetchData() {
            const db = getFirestore();
            const q = query(collection(db, "users"), where("disabled", "==", false));
            let querySnapshot = await getDocs(q);

            //remove type superuser
            querySnapshot = querySnapshot.docs.filter(doc => doc.data().type !== 'superuser');

            const ages = [];
            const userTypes = {};
            const addresses = [];

            querySnapshot.forEach((doc) => {
                const user = doc.data();
                const age = this.calculateAge(user.birthDate);
                if (age > 0) {
                    ages.push(age);
                }
                if (user.type) {
                    userTypes[user.type] = (userTypes[user.type] || 0) + 1;
                }
                if (user.address && user.country) {
                    const info = `
                        <div style='color:black'>
                            <strong>${user.displayName || 'Sin Nombre'}</strong><br>
                            Edad: ${age}<br>
                            Tipo: ${user.type || 'Unknown'}<br>
                            Dirección: ${user.address}, ${user.country}<br>
                        </div>`;
                    addresses.push({ address: `${user.address}, ${user.country}`, info });
                }
            });

            const ageGroups = {};
            ages.forEach(age => {
                const group = Math.floor(age / 5) * 5;
                ageGroups[group] = (ageGroups[group] || 0) + 1;
            });

            this.barChartOptions.xaxis.categories = Object.keys(ageGroups);
            this.barChartData = [{ data: Object.values(ageGroups) }];

            this.pieChartOptions.labels = Object.keys(userTypes);
            this.pieChartData = Object.values(userTypes);

            /* await this.geocodeAddresses(addresses);

            this.$nextTick(() => {
                this.initMap();
            }); */

            this.loading = false;
        },
        async geocodeAddresses(addresses) {
            const apiKey = 'AIzaSyBfKfiuvhd25up4XrdNpPWQ6Aozxsx-65g'; // Replace with your Google Maps API key
            const geocodePromises = addresses.map(({ address, info }) => {
                return axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&components=locality:Montevideo|country:UY&key=${apiKey}`)
                    .then(response => {
                        if (response.data.results.length > 0) {
                            const { lat, lng } = response.data.results[0].geometry.location;
                            return { lat, lng, info };
                        } else {
                            console.error(`No results for address: ${address}`);
                            return null;
                        }
                    })
                    .catch(error => {
                        console.error(`Error geocoding address: ${address}`, error);
                        return null;
                    });
            });

            this.coordinates = (await Promise.all(geocodePromises)).filter(coord => coord !== null);
        },
        initMap() {
            if (!window.google) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBfKfiuvhd25up4XrdNpPWQ6Aozxsx-65g&callback=initMap`;
                script.async = true;
                script.defer = true;
                script.onload = () => {
                    this.createMap();
                };
                document.head.appendChild(script);
            } else {
                this.createMap();
            }
        },
        createMap() {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 0, lng: 0 },
                zoom: 2
            });


            const bounds = new google.maps.LatLngBounds();


            this.coordinates.forEach(({ lat, lng, info }) => {
                const marker = new google.maps.Marker({
                    position: { lat, lng },
                    map: map
                });


                bounds.extend({ lat, lng });


                const infoWindow = new google.maps.InfoWindow({
                    content: info
                });

                marker.addListener('click', () => {
                    infoWindow.open(map, marker);
                });
            });

            map.fitBounds(bounds);

        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style>
/* Add any custom styles here */
#map {
    height: 400px;
    width: 100%;
}
</style>
